import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerPrimary from "../components/banners/bannerPrimary"
import FormContact from "../components/forms/formContact"
import Team from "../components/team"
import Reviews from "../components/reviews"
import Treatments from "../components/treatments"
import CallToAction from "../components/callToAction"
import Calander from "../components/calander"

export default function Contact() {
  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="Contact us and book your appointment with our easy-to-use online tool and enjoy a retreat-like experience in the rolling foothills of Alberta!"
      />
      <BannerPrimary
        to1="/"
        link1="Home"
        to2="/contact"
        link2="contact"
        title="contact us"
        description="Contact us and book your appointment with our easy-to-use online tool and enjoy a retreat-like experience in the rolling foothills of Alberta!"
      />
      <FormContact />
      <CallToAction />
      <Calander />
      <Reviews />
      <Treatments />
    </Layout>
  )
}
